import { Link } from "react-router-dom";
import { scrollTo } from "../Shared/SharedFunctions"
const bg1 = require('../assets/img/background.webp');
const logo = require('../assets/img/logo-invertiert.png');


export const Imprint= () =>{
    const openEMail = () =>{
        return (window.open(`mailto:kontakt@antik-cafe-lippetal.de`))   
        }

    return(<>
    
    <section id="home-imprint">
                
    <div className="home-item" style={{ backgroundImage: 'url('+bg1+')'}} > </div>
                <div className="home-container">
                    <div className='container'>
                        <div className="home-content">

                            <div className="logo">
                                <img src={logo} alt="logo" className="img-fluid" />
                            </div>
                             
                        </div>
                    </div>
                </div>


            </section>
         <div id="imprint" className="container my-5"> 
         <h1 className="mb-3">Impressum und Datenschutzhinweise</h1>
            Barbara Nolte<br/>
            Antik-Café<br/>
            Börneweg 5<br/>
            59510 Lippetal<br/>
            <br/>
            Kontakt:<br/>
            Telefon: 02923-659656 <br/>
            E-Mail: <a className="btn-cafe-black btn-sm mb-3" onClick={openEMail}>E-Mail schreiben</a><br/>
            <br/>
            Umsatzsteuer:<br/>
            Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:<br/>
            343/5099/2531<br/>
            <br/>
            
            <p>1. Inhalt des Onlineangebotes <br/>
                Wir übernehmen keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
                bereitgestellten Informationen. Haftungsansprüche gegen diese, welche sich auf Schäden materieller oder
                ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch
                die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich
                ausgeschlossen, sofern unsererseits kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden
                vorliegt. Alle Angebote sind freibleibend und unverbindlich. Wir behalten es uns ausdrücklich vor, Teile
                der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen
                oder die Veröffentlichung zeitweise oder endgültig einzustellen. Dieser Haftungsausschluss ist als Teil
                des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
                einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
                entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon
                unberührt.</p>
            <p>2. Verweise und Links<br/>
                Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des
                Verantwortungsbereiches von uns liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
                Kraft treten, in dem wir von den Inhalten Kenntnis hatten und es uns technisch möglich und zumutbar
                wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Wir erklären hiermit ausdrücklich, dass
                zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren.
                Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften
                Seiten haben wir keinerlei Einfluss. Deshalb distanzieren wir uns hiermit ausdrücklich von allen
                Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese
                Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise. Für
                illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder
                Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf
                welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich
                verweist.</p>
            <p>3. Urheber- und Kennzeichenrecht<br/>
                Wir sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente,
                Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen
                und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
                zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten
                Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen
                Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der
                bloßen Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
                sind! Das Copyright für veröffentlichte, von uns selbst erstellten Objekten bleibt allein bei der
                Redaktion der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
                Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
                Zustimmung unsererseits nicht gestattet.</p>
            <p>
                4. Datenschutz <br/>
                Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher
                Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des
                Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste
                ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
                anonymisierter Daten oder eines Pseudonyms gestattet. <br/>
                Diese Webseite verwendet keine oder ausschließlich technisch unbedingt notwendige Cookies.
                Auf Cookies zu Links zu externen Webseiten wie z.B. Google Maps haben wir keinen Einfluss.<br/>
                Kartendaten von <a href="http://www.openstreetmap.org/">OpenStreetMap</a> - Veröffentlicht unter <a href="http://opendatacommons.org/licenses/odbl/">ODbL</a> <br/>

            </p>
          
            <Link className="btn-cafe-black mb-3" to="/" state={"imprint"} >Zurück zur Hauptseite</Link>
                     
            </div>
          
    </>)
}