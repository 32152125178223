import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Feature } from './Feature/Feature';
import { Gallery } from './Gallery/Gallery';
import { Home } from './Home/Home';
import { Imprint } from "./Imprint/Imprint";
import { Navbar } from './Navbar/Navbar';
import ScrollToTop from "./Shared/SharedFunctions";

function App() {
  const [isMobileView, setMobileView] = useState(false);

  useEffect(() => {
    const onResize = () => setMobileView(window.visualViewport.width < 768);
    setMobileView(window.visualViewport.width < 768);
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize);

  }, []);

  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={
            <>
              <Navbar isMobileView={isMobileView} />
              <Home />
              <Feature />
              <Gallery />  
            </>}/>
            <Route path="/Imprint" element={
               <>
              <Navbar isMobileView={isMobileView} noNav={true} />
             
              <Imprint />
              </>}/>

        
      </Routes>
    </BrowserRouter>
    </>
  );

}

export default App;
