const feature = require('../assets/img/feature.webp');
export {Feature as default};
const openEMail = () =>{
    return (window.open(`mailto:kontakt@antik-cafe-lippetal.de`))   
    }


export const Feature = () => {
    return (<>
        <section id="cafe" className="about">

            <div className="row">

                <div className="col-md-5 box" style={{ backgroundImage: 'url(' + feature + ')' }}>
                </div>

                <div className="col-md-7">

                    <div className="content">
                        <h1 className='mb-4'>Selbstgebackener <strong>Kuchen</strong></h1>
                        <p className="fst-italic">
                            Ein Café mit historischem Charme, durch die Einrichtung mit antiken Möbeln und Accessoires aus unterschiedlichen Epochen und Gesellschaftskreisen.
                        </p>

                        <ul>
                            <li> Platz für Gesellschaften mit bis zu 80 Personen</li>
                            <li> Über 70 weitere Sitzmöglichkeiten auf dem Hofgelände</li>
                            <li> Barrierefreie Bauweise mit eigenem Parkplatz</li>
                        </ul>
                    </div>
                </div>
            </div>


        </section>
        <div id="contact" className='container-md text-center mt-5 mb-5'>
            <h1>Kontakt und Öffnungszeiten</h1>
            <p>Gerne reservieren wir auf Anfrage Tische für größere Gruppen</p>
        </div>

            <div className='container-md d-flex flex-column flex-sm-row justify-content-sm-evenly'>
                <div className="mb-3">
                    <h4 className="mb-2"><strong>Antik-Café</strong></h4>
                    <p>
                        Barbara Nolte<br />
                        Börneweg 5<br />
                        59510 Lippetal<br />
                        Telefon: 02923-659656<br />
                    </p>
                    <a className="btn-cafe-black btn-sm mb-3" onClick={openEMail}>E-Mail schreiben</a>
                    
                </div>

                <div>
                    <h4 className="mb-2"><strong>Öffnungszeiten</strong></h4>
                    <p>
                        Samstag, Sonntag: 14:00 - 18:00 Uhr<br />
                        An Feiertagen: 14:00 - 18:00 Uhr<br />
                        Nach Terminvereinbarung
                    </p>

                </div>
            </div>





            <div className='container-md text-center'>

                <iframe className="map-frame mb-3" loading="lazy"
                    src="https://www.openstreetmap.org/export/embed.html?bbox=8.125816583633425%2C51.62893517834928%2C8.129400014877321%2C51.63146755199218&amp;layer=mapnik&amp;marker=51.63020021736356%2C8.127607226306282">
                </iframe>

                <a href="https://goo.gl/maps/trCocfnVDfBiSbwi7" target="_blank"
                    className="btn-cafe-black mb-3">In Google Maps anzeigen</a>


            </div>

        </>);
}