import { Link } from "react-router-dom";

export const Gallery = () => {
    const img1 = require('../assets/img/1.webp');
const img2 = require('../assets/img/2.webp');
const img3 = require('../assets/img/3.webp');
const img4 = require('../assets/img/4.webp');
const img5 = require('../assets/img/5.webp');
const img7 = require('../assets/img/7.webp');
const img8 = require('../assets/img/8.webp');
const img9 = require('../assets/img/9.webp');
const img10 = require('../assets/img/10.webp');
const images = [img1, img2, img3, img4, img5, img7, img8, img9, img10];
const imgDiv = [];
const imgDiv2 = [];   

    images.forEach((img, i) => {
        if (i % 2 === 0) {
            imgDiv.push(
                <div className="gallery-item">
                    <a href={img} className="gallery-lightbox">
                        <img src={img} loading="lazy" alt="foto" className="w-100 my-1" />
                    </a>
                </div>)
        } else {
            imgDiv2.push(
                <div className="gallery-item">
                    <a href={img} className="gallery-lightbox">
                        <img src={img} loading="lazy" alt="foto" className="w-100 my-1" />
                    </a>
                </div>
            )
        }
    
    console.log("4 sek um");
    });


    return (
        <>
        
            <div className="container-md mt-5">
            <h1 id="gallery" className="mb-3">Bilder</h1>
                <div className="row">
                    <div className="col-md-6">
                        {imgDiv}
                    </div>
                    <div className="col-md-6">
                        {imgDiv2}
                    </div>
                </div>
            </div>

            <footer className="container pt-5 my-5 text-muted border-top">

                <div className="text-center">

                <Link className="text-muted" to="/Imprint" state={"imprint"} >Impressum und
                        Datenschutzhinweise</Link> &middot; Antik-Café
                    &middot; &copy; 2023

                    
                </div>

            </footer>
        </>)
}