import { useEffect, useState } from "react";
import { scrollTo } from "../Shared/SharedFunctions"

let logo = require('../assets/img/logo2.png');


export const Navbar = (props) => {

    const [showHeader, setHeaderVisibility] = useState(false);
    useEffect(() => {
        const onScroll = () => setHeaderVisibility(window.scrollY > window.visualViewport.height / 2);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const [menuOpen, setMenuOpenState] = useState(false);
    
    return (
        <> {!props.noNav&& <>
            <header id="header" className={showHeader ? "fixed-top d-flex header-scrolled" : "fixed-top d-flex header-transparent"} >   </header>
            <div className="container-md fixed-top ">
           
                {showHeader && (!menuOpen || !props.isMobileView) ?
                    <>
                        <div className="navbar-logo-container">
                            <img src={logo} alt="logo" onClick={() => scrollTo("#home")} className={props.isMobileView? "navbar-logo fade-in-top" :"navbar-logo" }/>
                        </div>
                    </> : null}

                {!props.isMobileView ? <>
                    <div className="d-flex justify-content-end">
                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><a className={showHeader ? "nav-link  invert" : "nav-link "} onClick={() => scrollTo("#cafe")} >Café</a></li>
                                <li><a className={showHeader ? "nav-link  invert" : "nav-link "} onClick={() => scrollTo("#contact")} >Kontakt</a></li>
                                <li><a className={showHeader ? "nav-link  invert" : "nav-link "} onClick={() => scrollTo("#gallery")} >Bilder</a></li>
                            </ul>
                        </nav>
                    </div>
                </> : <>

                    <div className="end">
                        <button onClick={() => setMenuOpenState(!menuOpen)} className={showHeader ? "navbar navbar-toggler" : "display-none"} type="button" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    </div>
                    {(menuOpen || !showHeader) &&
                        <>
                        <div className="d-flex justify-content-center">
                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><a className={showHeader ? "nav-link fade-in  invert" : "nav-link "} onClick={() => scrollTo("#cafe")} >Café</a></li>
                                <li><a className={showHeader ? "nav-link fade-in  invert" : "nav-link "} onClick={() => scrollTo("#contact")} >Kontakt</a></li>
                                <li><a className={showHeader ? "nav-link fade-in  invert" : "nav-link "} onClick={() => scrollTo("#gallery")} >Bilder</a></li>
                            </ul>
                        </nav>
                    </div>
                        </>
                    }
                </>

                }
                
            </div>
</> }
        </>

    );
};
